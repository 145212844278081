import React from 'react';
import { Button, Modal, Radio, useMediaQuery } from '@mui/material';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Control, FieldErrors, UseFormReset, UseFormWatch } from 'react-hook-form';
import { Timestamp } from 'firebase/firestore';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DownloadFile } from '../firebase/DownloadFilesFromStorage';
import { FormGridLayout } from '../studies/newStudy/components/FormGridLayout';
import { SelectAndIcon, SInput } from '../studies/newStudy/components/NewSimulationComponents';
import { COLORS } from '../style/colors';
import { Results } from '../types/Results';
import { serverRoutes } from '../routing/serverRoutes';
import { updateData } from '../firebase/firestoreQueries';
import { studiesCollection, studiesForSearchCollection } from '../firebase/firestoreCollections';
import { ModalContent } from '../components/ModalContent';
import { Loader } from '../studies/newStudy/components/Loader';
import { useNewStudy } from '../contexts/NewStudyContext';

export const PowerChoice = ({
  isCustomPower,
  setIsCustomPower,
  control,
  errors,
  watch,
  reset,
  isValid,
}: {
  isCustomPower: boolean;
  setIsCustomPower: React.Dispatch<React.SetStateAction<boolean>>;
  control: Control<{ customPower: number }>;
  errors: FieldErrors<{ customPower: number }>;
  watch: UseFormWatch<{ customPower: number }>;
  reset: UseFormReset<{ customPower: number }>;
  isValid: boolean;
}) => {
  const { t } = useTranslation();
  const { studyContext, setStudyContext } = useNewStudy();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const { results, study, customPowerResults } = studyContext;

  const [isLoading, setIsLoading] = React.useState(false);
  const [isInsufficientRoofModalOpen, setIsInsufficientRoofModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (customPowerResults) {
      reset({ customPower: study?.customPower });
    }
  }, [customPowerResults]);

  const handleCustomPowerChange = async () => {
    const newIsCustomPower = !isCustomPower;

    setStudyContext({
      ...studyContext,
      isCustomPowerSelected: newIsCustomPower,
    });

    const data = {
      uid: study?.uid,
      isCustomPowerSelected: newIsCustomPower,
    };

    await updateData(studiesCollection, data);
    await updateData(studiesForSearchCollection, data);

    setIsCustomPower(!isCustomPower);
  };

  const updateResults = async () => {
    if (!study) {
      return;
    }

    setIsLoading(true);
    try {
      const customPower = Number(watch().customPower);

      if (study.roofArea && study.roofArea < customPower / 150) {
        setIsInsufficientRoofModalOpen(true);
      } else {
        const rawResponse = await fetch(serverRoutes.studyComputation, {
          method: 'POST',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...study, customPower }),
        });

        const newResults = (await rawResponse.json()) as Results;

        await updateData(studiesCollection, {
          uid: study.uid || undefined,
          customPower,
          customPowerResults: newResults,
          isCustomPowerSelected: true,
          updatedAt: Timestamp.fromDate(new Date()),
        });
        await updateData(studiesForSearchCollection, {
          uid: study.uid || undefined,
          power: customPower,
          isCustomPowerSelected: true,
        });

        setStudyContext({
          ...studyContext,
          customPowerResults: newResults,
          isCustomPowerSelected: true,
          study: { ...study, customPower },
        });
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  if (!results || !study) {
    return null;
  }

  if (isLoading) {
    return (
      <Modal open>
        <ModalContent>
          <Loader />
        </ModalContent>
      </Modal>
    );
  }

  const shouldDisplayUpdateButton =
    isCustomPower && isValid && Number(watch().customPower) !== studyContext.study?.customPower;

  return (
    <>
      <div className="synthese-choix-puissance">
        <h1>{t('results.choixPuissance.titre')}</h1>

        {process.env.REACT_APP_ENVIRONMENT === 'development' ? (
          <>
            <DownloadFile
              fileName={`${study.uid}.doc`}
              buttonName="Télécharger le calcul des modèles"
            />
            <DownloadFile
              fileName={`${study.uid}.csv`}
              buttonName="Télécharger csv consommation/production/h"
            />
          </>
        ) : null}
        {isWeb ? (
          <>
            <FormGridLayout
              labelTranslationKey="results.choixPuissance.puissancePreconisee"
              items={[
                <EmptyInputContainer>
                  <span className="ml-2 font-bold text-green">
                    {results.recommendedPower} {t('wattPeak')}
                  </span>
                </EmptyInputContainer>,
                <Radio
                  checked={!isCustomPower}
                  onChange={handleCustomPowerChange}
                  name="isCustomPower"
                  inputProps={{ 'aria-label': 'false' }}
                />,
              ]}
            />
            <FormGridLayout
              labelTranslationKey="results.choixPuissance.puissancePersonnalisee"
              items={[
                <SelectAndIcon>
                  <InputContainer>
                    <SInput
                      name="customPower"
                      errors={errors}
                      control={control}
                      type="number"
                      placeholder=""
                      label=""
                      step="100"
                      min="0"
                      className="bg-white"
                    />
                  </InputContainer>
                  <Radio
                    checked={isCustomPower}
                    onChange={handleCustomPowerChange}
                    name="isCustomPower"
                    className="ml-5"
                    inputProps={{ 'aria-label': 'true' }}
                  />
                  <div className="ml-5">
                    {shouldDisplayUpdateButton ? (
                      <Button
                        onClick={updateResults}
                        variant="outlined"
                        sx={{
                          borderColor: COLORS.primaryColor,
                          color: COLORS.primaryColor,
                          backgroundColor: 'white',
                        }}>
                        {t('results.choixPuissance.updateSimulation')}
                        <SFontAwesomeIcon icon={faSyncAlt} color={COLORS.primaryColor} />
                      </Button>
                    ) : (
                      <div />
                    )}
                  </div>
                </SelectAndIcon>,
              ]}
            />
          </>
        ) : (
          <>
            <div className="flex justify-between">
              <div className="flex flex-1 justify-between">
                <MobileEmptyInputContainer className="flex flex-1">
                  <span className="font-bold">
                    {t('results.choixPuissance.puissancePreconisee')}
                  </span>
                  <span className="ml-16 font-bold text-green">
                    {results.recommendedPower} {t('wattPeak')}
                  </span>
                </MobileEmptyInputContainer>
                <div className="flex items-center">
                  <Radio
                    checked={!isCustomPower}
                    onChange={handleCustomPowerChange}
                    name="isCustomPower"
                    inputProps={{ 'aria-label': 'false' }}
                  />
                </div>
              </div>
            </div>
            <FormGridLayout
              labelTranslationKey="results.choixPuissance.puissancePersonnalisee"
              items={[
                <SelectAndIcon>
                  <span className="mr-2 flex w-full flex-1 font-bold">
                    {t('results.choixPuissance.puissancePersonnalisee')}
                  </span>
                  <InputContainer style={{ maxWidth: '100px' }}>
                    <SInput
                      name="customPower"
                      errors={errors}
                      control={control}
                      type="number"
                      placeholder=""
                      label=""
                      step="100"
                      min="0"
                      className="bg-white"
                    />
                  </InputContainer>
                  <Radio
                    checked={isCustomPower}
                    onChange={handleCustomPowerChange}
                    name="isCustomPower"
                    className="ml-5"
                    inputProps={{ 'aria-label': 'true' }}
                  />
                </SelectAndIcon>,
              ]}
            />
            <div className="mt-4 flex justify-center">
              {shouldDisplayUpdateButton ? (
                <Button
                  onClick={updateResults}
                  variant="outlined"
                  sx={{
                    borderColor: COLORS.primaryColor,
                    color: COLORS.primaryColor,
                    backgroundColor: 'white',
                  }}>
                  {t('results.choixPuissance.updateSimulation')}
                  <SFontAwesomeIcon icon={faSyncAlt} color={COLORS.primaryColor} />
                </Button>
              ) : (
                <div />
              )}
            </div>
          </>
        )}
      </div>

      <Modal open={isInsufficientRoofModalOpen}>
        <SModalContent>
          <div className="mt-10">
            <h2>{t('results.insufficientRoofTitle')}</h2>
          </div>

          <div className="mt-6 flex justify-center ">{t('results.insufficientRoofContent')}</div>

          <div className="my-5">
            <Button onClick={() => setIsInsufficientRoofModalOpen(false)} variant="contained">
              {t('ok')}
            </Button>
          </div>
        </SModalContent>
      </Modal>
    </>
  );
};

const SFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;

const InputContainer = styled('div')`
  width: 100%;
  max-width: 250px;
  margin-right: 25px;
`;

const EmptyInputContainer = styled(InputContainer)`
  padding: 10px 5px;
`;

const MobileEmptyInputContainer = styled(InputContainer)`
  padding: 10px 5px;
  max-width: 100%;
`;

const SModalContent = styled(ModalContent)`
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
