import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import '../style/results.scss';
import { COLORS } from '../style/colors';
import { clientRoutes } from '../routing/clientRoutes';
import { ResultsDetails } from './ResultsDetails';
import { EconomicModels } from './EconomicModels';
import { PowerChoice } from './PowerChoice';
import { ResultsCharts } from './ResultsCharts';
import { ConsumptionAlertModal } from './ConsumptionAlertModal';
import { useNewStudy } from '../contexts/NewStudyContext';
import { StudyAppHeader } from '../components/StudyAppHeader';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ScrollToTop } from '../util/ScrollToTop';

export const StudyResult = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { studyContext } = useNewStudy();
  const isWeb = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));

  const { results, study } = studyContext;

  const [isCustomPower, setIsCustomPower] = React.useState(
    studyContext.isCustomPowerSelected ?? (!!study?.customPower && !!study?.isCustomPowerSelected)
  );

  const {
    control,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm<{ customPower: number }>({
    mode: 'onChange' as const,
    reValidateMode: 'onChange' as const,
    resolver: yupResolver(
      yup
        .object()
        .shape({
          customPower: yup
            .number()
            .typeError('mustBeANumber')
            .min(1200, 'between1200and12000')
            .max(12000, 'between1200and12000')
            .required('requiredField'),
        })
        .required()
    ),
    defaultValues: {
      customPower: study?.customPower,
    },
  });

  const onClickSelectProducts = () => {
    navigate(clientRoutes.simulationProducts);
  };

  if (!results || !study) {
    return null;
  }

  const newCustomPower =
    isCustomPower && Number(watch().customPower) !== studyContext.study?.customPower;

  const isDisabled =
    !!(isCustomPower && study.roofArea && study.roofArea < Number(watch().customPower) / 150) ||
    (isCustomPower && !studyContext.customPowerResults) ||
    newCustomPower;

  const Content = (
    <>
      <ScrollToTop />

      <PowerChoice
        isCustomPower={isCustomPower}
        setIsCustomPower={setIsCustomPower}
        control={control}
        errors={errors}
        watch={watch}
        reset={reset}
        isValid={isValid}
      />

      {isWeb ? (
        <>
          <EconomicModels />
          <ResultsDetails />
        </>
      ) : (
        <>
          <ResultsDetails />
          <EconomicModels />
        </>
      )}

      <ResultsCharts />

      <div className="flex flex-col items-center">
        <SButton onClick={onClickSelectProducts} variant="contained" disabled={isDisabled}>
          {t('results.choisirProduit')}
        </SButton>
      </div>
    </>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <StudyAppHeader />

      {isWeb ? (
        <Background>
          <Container>{Content}</Container>
        </Background>
      ) : (
        <MobileContainer>{Content}</MobileContainer>
      )}

      <ConsumptionAlertModal />
    </Box>
  );
};

const Background = styled('div')`
  background-color: ${COLORS.greyPurple};
  padding: 16px 12px;
`;

const Container = styled('div')`
  background-color: ${COLORS.lighterGreyPurple};
  border-radius: 10px;
  padding: 30px;
`;

const MobileContainer = styled(Container)`
  background-color: ${COLORS.lighterGreyPurple};
  border-radius: 10px;
  padding: 30px 14px;
`;

const SButton = styled(Button)`
  width: 50%;
  min-width: 350px;
  display: block;
  margin-top: 15px !important;
`;
